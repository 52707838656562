import axios from 'axios'
const API = 'https://api.aflab.cl/'

export default {
  get (dir) {
    return axios.get(API + dir)
  },
  get1 (dir, var1) {
    return axios.get(API + dir + '/' + var1)
  },
  get2 (dir, var1, var2) {
    return axios.get(API + dir + '/' + var1 + '/' + var2)
  },
  get3 (dir, var1, var2, var3) {
    return axios.get(API + dir + '/' + var1 + '/' + var2 + '/' + var3)
  },
  post (dir, data) {
    return axios.post(API + dir, data)
  },
  postDownload (dir, data) {
    return axios.post(API + dir, data, { responseType: 'blob' })
  },
  get2bruto (dir, var1, var2) {
    axios.get(API + dir + '/' + var1 + '/' + var2).then((result) => {
      return result.data
    })
  },
  postUpload (dir, data) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return axios.post(API + dir, data, { headers })
  }
}
