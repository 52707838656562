import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Menu from '../views/Menus/menu.vue'
import CerrarSesion from '../views/cerrarsesion.vue'
const Pacientes = () => import('../views/Menus/pacientes.vue')
const InformesExamenes = () => import('../views/Menus/informesexamenes.vue')
const Examenes = () => import('../views/Menus/examenes.vue')
const PlantillasExamen = () => import('../views/Menus/plantillasexamen.vue')
const TiposExamen = () => import('../views/Menus/tiposexamen.vue')
const Muestras = () => import('../views/Menus/muestras.vue')
const Reactivos = () => import('../views/Menus/reactivos.vue')
const Metodos = () => import('../views/Menus/metodos.vue')
const Medidas = () => import('../views/Menus/medidas.vue')
// const Laboratorios = () => import('../views/Menus/laboratorios.vue')
const Usuarios = () => import('../views/Menus/usuarios.vue')

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'index', component: Index },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  { path: '/menu', name: 'menu', component: Menu },
  { path: '/pacientes', name: 'pacientes', component: Pacientes },
  { path: '/informesexamenes', name: 'informesexamenes', component: InformesExamenes },
  { path: '/examenes', name: 'examenes', component: Examenes },
  { path: '/plantillasexamen', name: 'plantillasexamen', component: PlantillasExamen },
  { path: '/tiposexamen', name: 'tiposexamen', component: TiposExamen },
  { path: '/muestras', name: 'muestras', component: Muestras },
  { path: '/reactivos', name: 'reactivos', component: Reactivos },
  { path: '/metodos', name: 'metodos', component: Metodos },
  { path: '/medidas', name: 'medidas', component: Medidas },
  // { path: '/laboratorios', name: 'laboratorios', component: Laboratorios },
  { path: '/usuarios', name: 'usuarios', component: Usuarios },
  { path: '/cerrarsesion', name: 'cerrarsesion', component: CerrarSesion }
]

const router = new VueRouter({
  routes
})

export default router
