<template>
  <v-app>
    <v-main>
      <v-app-bar
        color="primary"
        dark
        v-show="$store.state.menuvisible"
      >
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

        <v-toolbar-title>{{ nombreapp }}</v-toolbar-title>
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        class="primary"
      >
        <v-list
          nav
          dense
        >
          <v-list-item-group
            v-model="group"
            active-class="deep-blue--text text--accent-4"
          >
            <v-list-item v-for="item in $store.state.itemsmenu" v-bind:key="item.nombre" :to="item.goto">
              <v-list-item-icon>
                <v-icon class="white--text">{{item.icono}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="white--text">{{item.nombre}}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-container>
        <transition
          name="fade"
          mode="out-in"
        >
        <router-view></router-view>
        </transition>
      </v-container>
      <v-footer
        padless
        fixed
      >
        <v-card
          class="flex primary"
          flat
          tile
        >
          <v-card-text class="py-2 white--text text-center">
            {{ new Date().getFullYear() }} &copy; <strong><a href="https://bastware.cl" target="_blank" class="white--text">BastWare EIRL</a></strong>
          </v-card-text>
        </v-card>
      </v-footer>
      <v-overlay :value="$store.state.loading">
        <v-progress-circular
          indeterminate
          :size="100"
          color="primary"
          :width="10"
        ></v-progress-circular>
    </v-overlay>
    </v-main>
    <v-dialog
      v-model="updateExists"
      persistent
      :retain-focus="true"
    >
      <v-row no-gutters class="text-center" wrap align="center">
        <v-col
          cols="12"
          sm="12"
        >
          <v-card
            class="mx-auto"
            outlined
            tile
            elevation="5"
          >
          <v-card-title class="headline primary--text justify-center text-center" style="word-break: break-word;">
            Actualización disponible (obligatoria para continuar con un correcto uso del sistema)
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row align="center" justify="center" class="mt-3 mb-4">
                <v-btn
                  color="success"
                  @click="refreshApp"
                >
                  ACTUALIZAR
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
          </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
  </v-app>
</template>

<script>
import Vue from 'vue'
import VueSession from 'vue-session'
import update from '@/mixins/update'

Vue.use(VueSession)

export default {
  data: () => ({
    drawer: false,
    group: null,
    nombreapp: 'AFLAB'
  }),
  mixins: [update]
}
</script>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.15s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
</style>
