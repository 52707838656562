<template>
  <v-form ref="form" v-model="valid">
    <v-row class="mb-3">
      <v-col cols="12" sm="6" class="text-left">
        <p class="primary--text">La contraseña debe seguir las siguientes reglas:</p>
        <ul>
          <li>Mínimo de 8 caracteres</li>
          <li>Máximo de 15 caracteres</li>
          <li>Al menos una letra mayúscula</li>
          <li>Al menos una letra minúscula</li>
          <li>Al menos un dígito</li>
          <li>Sin espacios en blanco</li>
          <li>Al menos una caracter especial (#, %, &, etc.)</li>
        </ul>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          label="Contraseña"
          v-model="pass"
          :rules="passRules"
          counter
          maxlength="15"
          style="margin-bottom: -5px;"
          :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showpass ? 'text' : 'password'"
          @click:append="showpass = !showpass"
          class="mb-6 mt-9"
        ></v-text-field>
        <v-text-field
          label="Re ingrese la contraseña"
          v-model="repass"
          :rules="passRules"
          counter
          maxlength="15"
          style="margin-bottom: 10px;"
          :append-icon="showpass2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showpass2 ? 'text' : 'password'"
          @click:append="showpass2 = !showpass2"
        ></v-text-field>
        <v-row justify="center">
          <v-btn color="primary" class="mt-3" @click="validaPass">ACEPTAR</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Globales from '@/mixins/globales.vue'
export default {
  data: () => ({
    valid: false,
    pass: '',
    repass: '',
    passRules: [
      v => !!v || 'Este campo es obligatorio',
      v => !v || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/.test(v) || 'Cumpla las reglas, por favor'
    ],
    showpass: false,
    showpass2: false
  }),
  props: ['passok'],
  mixins: [
    Globales
  ],
  methods: {
    validaPass () {
      if (this.valid === true) {
        if (this.pass !== '' & this.repass !== '') {
          if (this.pass === this.repass) {
            this.passokInput = this.repass
            this.$refs.form.reset()
            alert('Contraseña aceptada')
          } else { alert('La contraseña ingresada no coincide.') }
        } else { alert('Debe completar los 2 campos de contraseña.') }
      } else {
        alert('Compruebe que los campos estén completados y se cumplen sus reglas de ingreso.')
      }
    }
  },
  computed: {
    passokInput: {
      get: function () {
        return this.passok
      },
      set: function (newValue) {
        this.$emit('update:passok', newValue)
      }
    }
  }
}
</script>
