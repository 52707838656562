/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('La aplicación AFLAB WEB está corriendo.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('Una actualización se está descargando.')
    },
    updated (registration) {
      console.log('Nueva actualización disponible. Por favor, recargue la página.')
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      )
    },
    offline () {
      console.log('No hay conexión a internet. La aplicación no funcionará.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
