<template><v-row></v-row></template>
<script>
import Vue from 'vue'
import VueSession from 'vue-session'
import axios from 'axios'

Vue.use(VueSession)

export default {
  data () {
    return {
      items: []
    }
  },
  methods: {
    cerrarSesion () {
      this.$session.destroy()
      axios.defaults.headers.common.Authorization = ''
      this.$store.commit('cambiarMenuVisible', false)
      this.$store.commit('cambiarItemsMenu', this.items)
      this.$router.push('/')
    }
  },
  created: function () {
    this.cerrarSesion()
  }
}
</script>
