<template>
  <DialogVentana :dialog.sync="dialogInput" :loading.sync="loading" :width="500">
    <v-stepper v-model="steps">
      <v-stepper-header>
        <v-stepper-step step="1" :complete="steps > 1">
          Seleccione Laboratorio <br> e ingrese Rut
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" :complete="steps > 2">
          Ingrese <br> Minipass
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="steps > 3">
          Cambie <br> Contraseña
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <p class="headline primary--text font-weight-regular mt-2">SELECCIONE SU LABORATORIO E INGRESE SU RUT</p>
          <v-form ref="form" v-model="valid">
            <v-autocomplete
              label="Laboratorio"
              :items="labs"
              v-model="lab"
              auto-select-first
              clearable
              no-data-text="Cargando datos..."
              :rules="[requerido]"
            ></v-autocomplete>
            <v-text-field
              v-model="usuario"
              :label="rutLabel"
              @change="validarRut"
              :rules="[requerido]"
              placeholder="11111111-1"
            ></v-text-field>
            <v-row
              align="center"
              justify="center"
            >
              <v-btn
                color="success"
                class="mt-5 mb-5"
                :disabled="!valid"
                @click="compruebaCredencial"
              >
                CONTINUAR
              </v-btn>
            </v-row>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="2">
          <p class="headline primary--text font-weight-regular mt-2">INGRESE EL N° MINIPASS QUE FUE ENVIADO A SU EMAIL</p>
          <v-form ref="form2" v-model="valid2">
            <v-text-field
              v-model="minipass"
              :rules="[requerido]"
              type="number"
              outlined
            ></v-text-field>
            <v-row
              align="center"
              justify="center"
            >
              <v-btn
                color="success"
                class="mt-5 mb-5"
                :disabled="!valid2"
                @click="compruebaMinipass"
              >
                CONTINUAR
              </v-btn>
            </v-row>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="3">
          <p class="headline primary--text font-weight-regular mt-2">INGRESE UNA NUEVA CONTRASEÑA</p>
          <EditarPass :passok.sync="passok"/>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </DialogVentana>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import DialogVentana from '@/components/dialogventana.vue'
import EditarPass from '@/components/editarpass.vue'
import ApiCon from '@/logic/apicon.js'

export default {
  data: () => ({
    valid: false,
    valid2: false,
    requerido: v => !!v || 'Este campo es obligatorio',
    loading: false,
    steps: 1,
    lab: '',
    usuario: '',
    rutLabel: 'RUT (sin puntos, con guión y dígito verificador.)',
    minipass: '',
    passok: '',
    min: 100000,
    max: 1000000
  }),
  props: ['dialog', 'labs'],
  mixins: [
    Globales
  ],
  components: {
    DialogVentana,
    EditarPass
  },
  methods: {
    validarRut () {
      this.usuario = this.validaRutFront(this.usuario)
    },
    async compruebaCredencial () {
      if (this.valid === true) {
        this.loading = true
        var minipassback = Math.floor(Math.random() * (this.max - this.min)) + this.min
        const ins = {
          NombreLab: this.lab,
          Usuario: this.usuario,
          Pass: minipassback.toString()
        }
        const response = await ApiCon.post('auth/compruebacredencial', ins)
        if (response.status === 200) {
          if (response.data === true) {
            this.$session.set('minipass', minipassback.toString())
            this.steps = 2
          } else { alert('Este usuario no se encuentra en el laboratorio seleccionado.') }
        } else { alert('mensaje error') }
        this.loading = false
      }
    },
    compruebaMinipass () {
      if (this.valid2 === true) {
        this.loading = true
        if (this.minipass === this.$session.get('minipass')) {
          this.steps = 3
        } else { alert('Ha ingresado un minipass incorrecto. Por favor, corrobore en el email que se usó para registrar su cuenta.') }
        this.loading = false
      }
    },
    async cambiarPass () {
      this.loading = true
      const ins = {
        NombreLab: this.lab,
        Usuario: this.usuario,
        Pass: this.passok
      }
      const response = await ApiCon.post('auth/cambiarpass', ins)
      if (response.status === 200) {
        this.steps = 4
        alert('Su contraseña ha sido renovada.')
        this.dialogInput = false
        this.$router.push('cerrarsesion')
      } else { alert('error cambiarPass') }
      this.loading = false
    },
    reset () {
      if (this.steps >= 2) {
        this.$session.destroy()
      }
      this.steps = 1
      this.lab = ''
      this.usuario = ''
      this.rutLabel = 'RUT'
      this.minipass = ''
      this.passok = ''
    }
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialog
      },
      set: function (newValue) {
        this.$emit('update:dialog', newValue)
      }
    },
    labsInput: {
      get: function () {
        return this.labs
      },
      set: function (newValue) {
        this.$emit('update:labs', newValue)
      }
    }
  },
  watch: {
    dialog () {
      if (this.dialog === false) {
        this.$refs.form.reset()
        this.$refs.form2.reset()
        this.reset()
      }
    },
    passok () {
      if (this.passok !== '') {
        this.cambiarPass()
      }
    }
  }
}
</script>
