<template>
  <v-dialog
    v-model="dialogInput"
    persistent
    :width="widthInput"
  >
    <v-card class="mt-2 primary" style="border-radius: 0px;">
      <v-card-actions>
        <v-row align="center" justify="end" class="cornflowerblue" style="z-index: 9999;">
        <v-btn
          color="error"
          dark
          fab
          small
          @click="dialogInput = false"
        >
        <v-icon dark>
          mdi-close
        </v-icon>
        </v-btn>
      </v-row>
      </v-card-actions>
    </v-card>
    <v-card
      class="mx-auto"
      outlined
      tile
      elevation="5"
    >
      <v-row no-gutters class="text-center" wrap align="center">
        <v-col cols="12" sm="12">
          <v-card-text>
            <slot></slot>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-overlay :value="loadingInput" :z-index="9999">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        :width="10"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    width: {
      type: Number
    }
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialog
      },
      set: function (newValue) {
        this.$emit('update:dialog', newValue)
      }
    },
    loadingInput: {
      get: function () {
        return this.loading
      },
      set: function (newValue) {
        this.$emit('update:loading', newValue)
      }
    },
    widthInput: {
      get: function () {
        return this.width
      },
      set: function (newValue) {
        this.$emit('update:width', newValue)
      }
    }
  }
}
</script>
