<script>
// @ is an alias to /src
// Componentes asociados: recuperarPass, registrarPaciente.

import ApiCon from '@/logic/apicon.js'
import Vue from 'vue'
import VueSession from 'vue-session'
import axios from 'axios'

Vue.use(VueSession)

export default {
  data: () => ({
    itemsMenu: []
  }),
  methods: {
    enSesion (tipoPagina) {
      if (this.$store.state.menuvisible === false & tipoPagina === 'sitio') {
        this.itemsMenu = this.menuItems()
        this.$store.commit('cambiarMenuVisible', true)
        this.$store.commit('cambiarItemsMenu', this.itemsMenu)
        this.$session.set('itemsmenu', JSON.stringify(this.itemsMenu))
      }
    },
    menuItems () {
      var items = []
      var tipoCuenta = parseInt(this.$session.get('tipocuenta'))
      items.push({ id: 1, nombre: 'MENU PRINCIPAL', icono: 'mdi-menu', goto: 'menu' })
      if (tipoCuenta === 1) {
        items.push({ id: 2, nombre: 'PACIENTES', icono: 'mdi-account', goto: 'pacientes' })
        items.push({ id: 3, nombre: 'INFORMES DE EXÁMENES', icono: 'mdi-clipboard-text', goto: 'informesexamenes' })
        items.push({ id: 4, nombre: 'EXÁMENES', icono: 'mdi-test-tube', goto: 'examenes' })
        items.push({ id: 5, nombre: 'PLANTILLAS DE EXÁMEN', icono: 'mdi-clipboard-text-multiple', goto: 'plantillasexamen' })
        items.push({ id: 10, nombre: 'USUARIOS', icono: 'mdi-clipboard-account', goto: 'usuarios' })
        // items.push({ id: 6, nombre: 'LABORATORIOS Y SUCURSALES', icono: 'mdi-home', goto: 'laboratorios' })
      } else if (tipoCuenta === 2) {
        items.push({ id: 2, nombre: 'PACIENTES', icono: 'mdi-account', goto: 'pacientes' })
        items.push({ id: 7, nombre: 'INFORMES DE EXÁMENES', icono: 'mdi-clipboard-text', goto: 'informesexamenes' })
        items.push({ id: 8, nombre: 'EXÁMENES', icono: 'mdi-test-tube', goto: 'examenes' })
        items.push({ id: 9, nombre: 'PLANTILLAS DE EXÁMEN', icono: 'mdi-clipboard-text-multiple', goto: 'plantillasexamen' })
        items.push({ id: 10, nombre: 'USUARIOS', icono: 'mdi-clipboard-account', goto: 'usuarios' })
      } else if (tipoCuenta === 3 | tipoCuenta === 4) {
        items.push({ id: 2, nombre: 'PACIENTES', icono: 'mdi-account', goto: 'pacientes' })
        items.push({ id: 7, nombre: 'INFORMES DE EXÁMENES', icono: 'mdi-clipboard-text', goto: 'informesexamenes' })
        items.push({ id: 10, nombre: 'USUARIOS', icono: 'mdi-clipboard-account', goto: 'usuarios' })
      } else if (tipoCuenta === 5) {
        items.push({ id: 7, nombre: 'INFORMES DE EXÁMENES', icono: 'mdi-clipboard-text', goto: 'informesexamenes' })
      }
      items.push({ id: 11, nombre: 'CERRAR SESIÓN', icono: 'mdi-close-box', goto: 'cerrarsesion' })
      return items
    },
    validaRutFront (rut) {
      if (this.validaRut(rut) === true) {
        return this.formateaRut(rut)
      } else {
        alert('Rut ingresado inválido. Recuerde ingresar sin puntos, con guión y digito verificador')
        rut = ''
        return rut
      }
    },
    validaRut (rutCompleto) {
      if (!/^[0-9]+[-]{1}[0-9kK]{1}$/.test(rutCompleto)) {
        return false
      }
      var tmp = rutCompleto.split('-')
      var digv = tmp[1]
      var rut = tmp[0]
      if (digv === 'k') digv = 'K'
      return (this.dv(rut) === digv)
    },
    dv (T) {
      var M = 0
      var S = 1
      for (;T; T = Math.floor(T / 10)) {
        S = (S + T % 10 * (9 - M++ % 6)) % 11
      }
      return S ? (S - 1).toString() : 'K'
    },
    formateaRut (rut) {
      var div1
      var div2
      var div3
      var div4
      rut = rut.replace('-', '')
      if (rut.length === 9) {
        div1 = rut.slice(0, 2)
        div2 = rut.slice(2, 5)
        div3 = rut.slice(5, 8)
        div4 = rut.slice(8, 9)
        if (div4 === 'k') {
          div4 = 'K'
        }
        return div1 + '.' + div2 + '.' + div3 + '-' + div4
      }
      if (rut.length === 8) {
        div1 = rut.slice(0, 1)
        div2 = rut.slice(1, 4)
        div3 = rut.slice(4, 7)
        div4 = rut.slice(7, 8)
        if (div4 === 'k') {
          div4 = 'K'
        }
        return div1 + '.' + div2 + '.' + div3 + '-' + div4
      }
    },
    compruebaLogin () {
      if (!this.$session.exists('key')) {
        this.$router.push('/cerrarsesion')
        return false
      } else {
        axios.defaults.headers.common.Authorization = 'Bearer ' + this.$session.get('key')
        axios.interceptors.response.use(
          response => response,
          error => {
            if (error.response.status === 401) {
              alert('Su sesión ha caducado. Por favor, vuelva a ingresar al sistema.')
              this.$router.push('cerrarsesion')
            }
          })
        this.$store.commit('cambiarMenuVisible', true)
        this.$store.commit('cambiarItemsMenu', JSON.parse(this.$session.get('itemsmenu')))
        return true
      }
    },
    async haySesion () {
      const response = await ApiCon.get('auth/compruebasesion')
      if (response.status === 200) {}
    },
    returnPrilegio (nombre) {
      var privs = JSON.parse(this.$session.get('privilegios'))
      for (let index = 0; index < privs.length; index++) {
        if (privs[index].nombre === nombre) {
          return privs[index].access
        }
      }
    },
    async cargarTabla (api, store, error) {
      this.$store.commit(store, [])
      const response = await ApiCon.get1(api, this.$session.get('usuario'))
      if (response.status === 200) {
        this.$store.commit(store, response.data)
      } else { alert('Error en ' + error + '...') }
    },
    validaLargoChars (texto, largo) {
      for (let index = 0; index < texto.length; index++) {
        if (texto[index].length > largo) {
          return false
        }
      }
      return true
    },
    async cargarTablaFiltro (api, store, error, filtro) {
      this.$store.commit(store, [])
      const response = await ApiCon.get2(api, this.$session.get('usuario'), filtro)
      if (response.status === 200) {
        this.$store.commit(store, response.data)
      } else { alert('Error en ' + error + '...') }
    },
    procesaPDF (data, tipo) {
      const blob = new Blob([data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = tipo
      window.open(link.href, '_blank')
      URL.revokeObjectURL(link.href)
    },
    removeSpecialCharacters (charactersString) {
      return charactersString.replace(/[^\w\s]/gi, '')
    },
    checkKeyDownAlphaNumeric (event) {
      if (!/[a-zÀ-úA-Z0-9.\s]/.test(event.key)) {
        this.ignoredValue = event.key ? event.key : ''
        event.preventDefault()
      }
    }
  }
}
</script>
