<template>
   <v-row no-gutters class="text-center" wrap align="center" style="margin-top: 30px">
    <v-col
      cols="12"
      sm="12"
    >
    <p class="display-2 primary--text font-weight-regular">AFLAB</p>
      <v-card
        class="mx-auto"
        outlined
        tile
        elevation="5"
        max-width="720"
        style="padding: 25px;"
      >
      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="login">
          <v-autocomplete
            label="Laboratorio"
            :items="labs"
            v-model="lab"
            auto-select-first
            clearable
            no-data-text="Cargando datos..."
            :rules="[requerido]"
          ></v-autocomplete>
          <v-text-field
            v-model="usuario"
            :label="rutLabel"
            @change="validarRut"
            :rules="[requerido]"
            placeholder="11111111-1"
          ></v-text-field>
          <v-text-field
            v-model="pass"
            label="Contraseña"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showpass ? 'text' : 'password'"
            @click:append="showpass = !showpass"
            :rules="[requerido]"
          ></v-text-field>
          <v-row
            align="center"
            justify="center"
          >
          <v-btn
            color="success"
            class="mt-5 mr-5"
            type="submit"
            :disabled="!valid"
          >
            INGRESAR
          </v-btn>
          <v-btn
            color="primary"
            class="mt-5"
            @click="sheet = !sheet"
          >
            + OPCIONES
          </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      </v-card>
    </v-col>
    <v-bottom-sheet v-model="sheet">
      <v-sheet
        class="text-center"
        height="280px"
      >
        <v-btn
          class="mt-1"
          text
          color="red"
          @click="sheet = !sheet"
        >
          CERRAR
        </v-btn>
        <div>
          <p>¿Olvidó su contraseña? Recuperela aquí:</p>
          <v-btn
            color="primary"
            @click="dialogRecuperarPass = true"
          >
            RECUPERAR CONTRASEÑA
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <RecuperarPass :dialog.sync="dialogRecuperarPass" :labs.sync="labs"/>
  </v-row>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import ApiCon from '@/logic/apicon.js'
import axios from 'axios'
import RecuperarPass from '@/components/recuperarpass.vue'

export default {
  mixins: [
    Globales
  ],
  data: () => ({
    sheet: false,
    valid: false,
    labs: [],
    lab: '',
    usuario: '',
    pass: '',
    showpass: false,
    rutLabel: 'RUT (sin puntos, con guión y dígito verificador.)',
    requerido: v => !!v || 'Este campo es obligatorio',
    dialogRecuperarPass: false
  }),
  components: {
    RecuperarPass
  },
  methods: {
    async cargarLabs () {
      const response = await ApiCon.get('laboratorios/todos')
      if (response.status === 200) {
        this.labs = response.data
      } else { alert('Error en cargarLabs...') }
    },
    async login () {
      if (this.valid === true) {
        const cred = {
          NombreLab: this.lab,
          Usuario: this.usuario,
          Pass: this.pass
        }
        try {
          this.$store.commit('cambiarLoading', true)
          const response = await ApiCon.post('auth/login', cred)
          if (response.status === 200) {
            if (response.data[0].estado !== 'Activado') {
              alert('Su cuenta está desactivada. Póngase contacto con el administrador.')
              this.$router.push('cerrarsesion')
            } else {
              axios.defaults.headers.common.Authorization = 'Bearer ' + response.data[0].key
              axios.interceptors.response.use(
                response => response,
                error => {
                  if (error.response.status === 401) {
                    alert('Su sesión ha caducado. Por favor, vuelva a ingresar al sistema.')
                    this.$router.push('cerrarsesion')
                  }
                })
              this.$session.set('key', response.data[0].key)
              this.$session.set('tipocuenta', response.data[0].tipocuenta)
              this.$session.set('privilegios', JSON.stringify(response.data[0].privilegios))
              this.$session.set('usuario', response.data[0].id)
              this.enSesion('sitio')
              this.$router.push('menu')
            }
          } else {
            alert('El usuario y/o contraseña ingresados son incorrectos.')
            this.usuario = ''
            this.pass = ''
          }
        } catch (error) {
          alert('El usuario y/o contraseña ingresados son incorrectos.')
        }
      } else { alert('Por favor, seleccione su laboratorio e ingrese su usuario y contraseña.') }
      this.$store.commit('cambiarLoading', false)
    },
    validarRut () {
      this.usuario = this.validaRutFront(this.usuario)
    },
    cargarDatos () {
      this.$store.commit('cambiarLoading', true)
      if (this.$session.exists('key') === true) {
        this.$router.push('menu')
      } else { this.$session.destroy() }
      this.$store.commit('cambiarMenuVisible', false)
      this.cargarLabs()
      this.$store.commit('cambiarLoading', false)
    }
  },
  created () {
    this.cargarDatos()
  }
}
</script>
