<template>
   <v-row no-gutters class="text-center mb-12 mt-3" wrap align="center">
    <v-col
      cols="12"
      sm="12"
    >
    <p class="display-2 primary--text font-weight-regular">
      <v-icon x-large class="primary--text">
        {{icono}}
      </v-icon>{{titulo}}</p>
      <v-card
        class="mx-auto"
        outlined
        tile
        elevation="5"
      >
      <v-card-text>
        <v-form
          ref="form"
        >
        <v-row
            justify="center"
            class="mb-2"
          >
          <v-btn v-for="boton in botones" v-bind:key="boton.id"
            :color="boton.color"
            class="mt-5 mr-1"
            style="width: 200px;"
            @click="clicBoton(boton.accion)"
          >
            {{boton.nombre}}
          </v-btn>
          </v-row>
            <v-text-field
            v-model="filtrar"
            append-icon="mdi-magnify"
            label="Filtrar"
            single-line
            hide-details
            v-show="false"
          ></v-text-field>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filtrar"
            :mobile-breakpoint='0'
            hide-default-header
            class="mt-5 mb-5"
            :hide-default-footer="true"
            :items-per-page="12"
          >
          <template v-slot:header="{ props }">
            <th v-for="head in props.headers" v-bind:key="head.text" class="headline primary--text" :align="alineaHeaders">
              {{ head.text.toUpperCase() }}
            </th>
          </template>
          <template v-slot:item="props">
            <tr @click="opcionesRow(props.item, props.index)">
              <td align="center" class="primary--text"><v-icon large class="primary--text" style="margin-top:-5px;">{{ props.item.icono }}</v-icon>
                {{ props.item.nombre }}</td>
            </tr>
          </template>
          </v-data-table>
        </v-form>
      </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import Vue from 'vue'
import VueSession from 'vue-session'

Vue.use(VueSession)

export default {
  mixins: [
    Globales
  ],
  data: () => ({
    filtrar: '',
    filtrovisible: true,
    headers: [],
    items: [],
    botones: [],
    alineaHeaders: 'center',
    titulo: 'Menú Principal',
    icono: 'mdi-menu'
  }),
  methods: {
    menuPrincipal () {
      this.headers = [
        { text: 'SECCIONES', align: 'center', value: 'opcion' }
      ]
      // usar itemmenu guardado en session para esta carga
      this.items = JSON.parse(this.$session.get('itemsmenu'))
      // this.items = this.$store.state.itemsmenu
      this.items.splice(0, 1)
      this.botones = [
        // { id: 1, nombre: 'CUENTAS', color: 'primary', accion: 'adminCuenta' },
        // { id: 2, nombre: 'CONFIGURACIONES', color: 'primary', accion: 'configs' },
        // { id: 3, nombre: 'CERRAR SESIÓN', color: 'error', accion: 'cerrarSesion' }
      ]
    },
    clicBoton (accion) {
      if (accion === 'adminCuenta') {
      } else if (accion === 'configs') {
      } else if (accion === 'cerrarSesion') {
        this.$router.push('/')
      }
    },
    opcionesRow (item, index) {
      // var destino = ''
      this.$router.push(item.goto)
    },
    cargarDatos () {
      if (this.compruebaLogin() === true) {
        if (this.returnPrilegio('menu') === true) {
          this.menuPrincipal()
        } else { this.$router.push('menu') }
      }
    }
  },
  created () {
    this.haySesion()
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.2rem !important;
    font-weight:500;
    color: dodgerblue;
    /* text-decoration: underline; */
    border: 1px solid;
  }
</style>
